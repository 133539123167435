/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

import {environment} from './environments/environment';
(window as any).global = window;
if (environment.production) {
    console.log('%cStop!', 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;');
    console.log('%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a GOFRUGAL feature or \'hack\' someones account, it is a scam and will give them access to your GOFRUGAL account.', 'font-size: 18px;');
    console.log = function() { };
    console.warn = function() { };
    console.error = function() { };
    console.debug = function() { };
    console.trace = function() { };
    console.assert = function() { };
    console.dir = function() { };
    console.count = function() { };
    console.dirxml =  function() { };
    console.group = function() { };
    console.groupCollapsed = function() { };
    console.groupEnd = function() { };
} else {
    console.log('%cDeveloper Mode!', 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;');
    console.log('%cPlease clear you console while commiting the code', 'font-size: 18px;');
}


