export const environment = {
  production: true,
  locationURL: 'https://ordereasy.gofrugal.com/',
  alertURL: 'https://alert.gofrugal.com/Alert/',
  ecomURL: 'https://ordereasy.gofrugal.com/ecommerce/',
  ondcURL: 'https://ordereasy.gofrugal.com/ondc/',
  accountURL: 'https://ordereasy.gofrugal.com/accounts/',
  paymentURL: 'https://ordereasy.gofrugal.com/payment/',
  samURL: 'https://sam.gofrugal.com/',
  deliveryURL: 'https://ordereasy.gofrugal.com/delivery-service/api/v1/',
  smartReports: 'https://ordereasy.gofrugal.com/reports/#/reports?',
  samAddressDetailURL: 'https://sam.gofrugal.com/ismile/',
  analyticsURL: 'https://ordereasy.gofrugal.com/app-analytics/api/v1/',
  srDashboard: 'https://ordereasy.gofrugal.com/reports/#/dashBoard?',
  storePurchase: 'https://store.gofrugal.com/service_index.php?',
  skewCode: '742',
  skewName: 'OrderEasy',
  validAddons: ['742', '538', '744'],
  defaultUrl: 'Integration/ecommerce/sr-dashboard/ordereasy/ecommerce',
  mainHeadnig: 'Your online order management',
  secondHeading: 'platform connected with delivery management to gain more orders and expand your customer base',
  bgColor: '#F9F9FB',
};
